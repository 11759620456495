import { graphql } from 'gatsby'
import React from 'react'
import { SectionBlock } from 'starterUiComponents'

const FaqBlock = (props) => {
  const {
    data: {
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  return flexibleLayouts?.map((card) => {
    const { anchor, cssclass, title, faq } = card
    return (
      <SectionBlock key={title} anchor={anchor} cssclass={cssclass}>
        {title && (
          <h4 className="pt-10 font-bold border-t mb-7 text-l border-border">
            {title}
          </h4>
        )}
        {faq?.map((item, i) => {
          const { question, answer } = item
          return (
            <div key={i} className="mb-10">
              <div className="font-bold font-heading text-md">{question}</div>
              <div dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
          )
        })}
      </SectionBlock>
    )
  })
}

export const faqPageQuery = graphql`
  query WpPageFaqTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          ... on WpPage_Flexlayouts_FlexibleLayouts_FaqBlock {
            title
            cssclass
            anchor
            faq {
              question
              answer
            }
          }
        }
      }
    }
  }
`

export default FaqBlock
